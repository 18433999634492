<script>
  import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";
// import Switches from "vue-switches";
export default {
    components:{Multiselect},
    props:['service','ODS','send','pos','estimated','disable','planB','period'],
    data(){
        return {
            active:false,
            addPersonel:false,
            fields:[{poste:"",qte:0,amount:0}],
            fieldsD:[],
            selectedProducts:[{product:"",qte:0,amount:0}],
            selectedMaterials:[{equipment:"",qte:0,amount:0}],
            selectedMaterialsD:[],
            PostesList:[],
            PostesListOrdering:[],
            EQPListOrdering:[],
            ProductsList:[],
            EquipmentsList:[],
            MaterialsList:[],
            RH:[],
            postes:[],
            retrieveRH:false,
            retrieveEQP:false,
            AmountEquipments:0,
            AmountPostes:0,
        }
    },
    
    mounted(){
        
            switch (this.pos) {
                case 'Prestation de Services':
            
                    if(this.service[this.pos][0].quantity > 0){
                        this.service[this.pos].forEach(x => {
                            this.fields = [];
                            this.fields.push({id:x.id,poste:x.prestation,qte:x.quantity,amount:x.amount})
                            // if(x.estimation_type == 'when_ordering')this.PostesList.push(x.service)
                            // else this.PostesListOrdering.push(x.service)
                        })
                    }
                    else {
                        this.PostesListOrdering = this.service[this.pos];
                    }

                    this.getAmount();

                    break;
                case 'Acquisition Fournitures':
                    if(this.service[this.pos][0].quantity > 0){
                        this.service[this.pos].forEach(element => {
                            this.selectedProducts = [];
                            this.selectedProducts.push({id:element.id,product:element.product,qte:element.quantity,amount:element.amount})
                            this.ProductsList.push(element.product)
                        })
                    }
                    else this.ProductsList = this.service[this.pos];

                    this.getAmount();
                    break;
            
                default:
                    
                    break;
            }
        
    },
    methods:{
        selectedPoste(poste,i){
            // console.log(this.fields);
            this.fields[i].poste = poste;
            this.fields[i].qte = poste.estimated_qty;
            this.getAmount();
        },
        AddformData() {
            this.fields.push({selctedPoste:"",qte:"",amount:0});
        },
        AddformDataProduct() {
            this.selectedProducts.push({product:"",qte:"",amount:0});
        },
        AddformDataMat() {
            this.selectedMaterials.push({equipment:"",qte:"",amount:0});
        },
        deleteRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fields.splice(index, 1);
                }
            })
        },
        deleteProductRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedProducts.splice(index, 1);
                }
            })
        },
        deleteMatRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedMaterials.splice(index, 1);
                }
            })
            
        },
        getAmount(){
            if(this.pos == 'Prestation de Services'){
                this.fields.forEach(element => {
                    
                    element.amount = parseInt(element.poste.unit_price) * parseInt(element.qte)
                    console.log( parseInt(element.amount));
                })
                // console.log(this.fields);
                this.TotalAmount()
            }
            if(this.pos == 'Acquisition Fournitures'){
                 this.selectedProducts.forEach(element => {
                     element.amount = parseInt(element.product.unit_price) * parseInt(element.qte)
                 })
                //  console.log(this.selectedProducts);
                 this.TotalAmount()
            }
        },
        TotalAmount(){
            // this.getAmount();
            var total = 0;
            if(this.pos == 'Prestation de Services'){
                total = this.fields.reduce((carry,item)=> carry += parseInt(item.amount),0);
                if(total > 0){
                    this.AmountPostes = total
                    this.$emit('getvalues',this.fields)
                    this.$emit('getvaluesEmitted',true)
                }
                else total = 0;
            }

            if(this.pos == 'Acquisition Fournitures'){
                 total = this.selectedProducts.reduce((carry,item) => carry+=parseInt(item.amount),0);

                if(total > 0){
                    this.AmountEquipments = total;
                    this.$emit('getvalues',this.selectedProducts);
                    this.$emit('getvaluesEmitted',true);
                }
                else total = 0;
            }
            return total;
        },
        customLabel({name}){
            return `${name}` 
        },
        customLabelEQP({equipments}){
            return `${equipments.designation}` ;
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                // this.getAmount();
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1" v-if="pos == 'Prestation de Services'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Prestation de Services </div>
                            
                            <div class="col text-right">
                                <span v-if="canAny(['validate_threedops_ods','confirm_threedops_ods'])">Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(AmountPostes)}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="p-3">
                                <div class="row">
                                    <div  class="col">
                                    <div class="">
                                        <div class="row" v-for="(field,i) in fields" :key="i">
                                            <div class="col-md-4">
                                                <label for="">Prestation</label>
                                                
                                                <multiselect 
                                                    v-model="field.poste" 
                                                    class="here" 
                                                    @select="selectedPoste($event,i)"
                                                    openDirection="below"  
                                                    deselect-label="" 
                                                    track-by="id" 
                                                    label="name" 
                                                    :custom-label="customLabel" 
                                                    :show-labels="false"
                                                    placeholder="" 
                                                    :options="PostesListOrdering" 
                                                    :searchable="false" 
                                                    :allow-empty="false">
                                                </multiselect>
                                            </div>
                                            <div class="col-md-2" v-if="fields[i].poste&&fields[i].poste.uom">
                                                <label for="">Unité </label>
                                                <input class="form-control" type="text" disabled v-model="fields[i].poste.uom.designation">
                                            </div>
                                            <div class="col-md-2">
                                                <label for="">Quantité Demandé </label>
                                                <input class="form-control" type="number" min="0" placeholder="20" v-model="field.qte" @keypress="isNumber($event)" @input="getAmount()">
                                            </div>
                                            <div class="col-md-2">
                                                <label for="">Délai de l'Opération </label>
                                                <input class="form-control" type="text" v-model="period" readonly>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                
                <b-card no-body class="mb-1" v-if="pos == 'Acquisition Fournitures'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Acquisition Fournitures</div>
                            <div class="col text-right">
                                <span  v-if="canAny(['validate_threedops_ods','confirm_threedops_ods'])">Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(AmountEquipments)}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg" style="heigth:200px">
                            <div class="row" v-for="(selectedProduct,i) in selectedProducts" :key="i">
                                <div class="col-md-4">
                                    <label for="">Liste Produits</label>
                                    
                                    <multiselect class="here" v-model="selectedProduct.product" openDirection="below" :maxHeight="500" :options="ProductsList" :multiple="false" :group-select="false" placeholder="Saisir pour recherche" track-by="id" label="name">
                                        <span slot="noResult">Aucun Résultat Trouvé</span>
                                    </multiselect>
                                </div>
                                <div class="col-md-2" v-if="selectedProduct.product.uom">
                                    <label for="">Unité</label>
                                    <input class="form-control" type="text" disabled  v-model="selectedProduct.product.uom.designation">
                                </div>
                                <div class="col-md-2">
                                    <label for="">Quantité Demandé</label>
                                    <input class="form-control" type="number" readonly placeholder="20" v-model="selectedProduct.qte"  @keypress="isNumber($event)" @input="getAmount">
                                </div>
                                <div class="col-md-2">
                                    <label for="">Délai de l'Opération </label>
                                    <input class="form-control" type="text" v-model="period" readonly>
                                </div>
                                <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteProductRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-right">
                                    <button class="btn btn-info" type="button" @click="AddformDataProduct"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>
<style>
.here .multiselect__content-wrapper{
    position:relative !important;
    max-height: 300px !important;
}
</style>