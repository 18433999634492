var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
        [
          _vm.pos == "Prestation de Services"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Prestation de Services ")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.canAny([
                                "validate_threedops_ods",
                                "confirm_threedops_ods"
                              ])
                                ? _c("span", [
                                    _vm._v(
                                      "Montant : " +
                                        _vm._s(
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(_vm.AmountPostes)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "p-3" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                {},
                                _vm._l(_vm.fields, function(field, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-4" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v("Prestation")
                                          ]),
                                          _c("multiselect", {
                                            staticClass: "here",
                                            attrs: {
                                              openDirection: "below",
                                              "deselect-label": "",
                                              "track-by": "id",
                                              label: "name",
                                              "custom-label": _vm.customLabel,
                                              "show-labels": false,
                                              placeholder: "",
                                              options: _vm.PostesListOrdering,
                                              searchable: false,
                                              "allow-empty": false
                                            },
                                            on: {
                                              select: function($event) {
                                                return _vm.selectedPoste(
                                                  $event,
                                                  i
                                                )
                                              }
                                            },
                                            model: {
                                              value: field.poste,
                                              callback: function($$v) {
                                                _vm.$set(field, "poste", $$v)
                                              },
                                              expression: "field.poste"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.fields[i].poste &&
                                      _vm.fields[i].poste.uom
                                        ? _c(
                                            "div",
                                            { staticClass: "col-md-2" },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "" } },
                                                [_vm._v("Unité ")]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.fields[i].poste.uom
                                                        .designation,
                                                    expression:
                                                      "fields[i].poste.uom.designation"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  disabled: ""
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.fields[i].poste.uom
                                                      .designation
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.fields[i].poste.uom,
                                                      "designation",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "col-md-2" }, [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Quantité Demandé ")
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: field.qte,
                                              expression: "field.qte"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "number",
                                            min: "0",
                                            placeholder: "20"
                                          },
                                          domProps: { value: field.qte },
                                          on: {
                                            keypress: function($event) {
                                              return _vm.isNumber($event)
                                            },
                                            input: [
                                              function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  field,
                                                  "qte",
                                                  $event.target.value
                                                )
                                              },
                                              function($event) {
                                                return _vm.getAmount()
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("div", { staticClass: "col-md-2" }, [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Délai de l'Opération ")
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.period,
                                              expression: "period"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text", readonly: "" },
                                          domProps: { value: _vm.period },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.period = $event.target.value
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Acquisition Fournitures"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Acquisition Fournitures")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.canAny([
                                "validate_threedops_ods",
                                "confirm_threedops_ods"
                              ])
                                ? _c("span", [
                                    _vm._v(
                                      "Montant : " +
                                        _vm._s(
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(_vm.AmountEquipments)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        {
                          staticClass: "shadow-lg",
                          staticStyle: { heigth: "200px" }
                        },
                        [
                          _vm._l(_vm.selectedProducts, function(
                            selectedProduct,
                            i
                          ) {
                            return _c("div", { key: i, staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Liste Produits")
                                  ]),
                                  _c(
                                    "multiselect",
                                    {
                                      staticClass: "here",
                                      attrs: {
                                        openDirection: "below",
                                        maxHeight: 500,
                                        options: _vm.ProductsList,
                                        multiple: false,
                                        "group-select": false,
                                        placeholder: "Saisir pour recherche",
                                        "track-by": "id",
                                        label: "name"
                                      },
                                      model: {
                                        value: selectedProduct.product,
                                        callback: function($$v) {
                                          _vm.$set(
                                            selectedProduct,
                                            "product",
                                            $$v
                                          )
                                        },
                                        expression: "selectedProduct.product"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "noResult" },
                                          slot: "noResult"
                                        },
                                        [_vm._v("Aucun Résultat Trouvé")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              selectedProduct.product.uom
                                ? _c("div", { staticClass: "col-md-2" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Unité")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            selectedProduct.product.uom
                                              .designation,
                                          expression:
                                            "selectedProduct.product.uom.designation"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", disabled: "" },
                                      domProps: {
                                        value:
                                          selectedProduct.product.uom
                                            .designation
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            selectedProduct.product.uom,
                                            "designation",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Quantité Demandé")
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: selectedProduct.qte,
                                      expression: "selectedProduct.qte"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    readonly: "",
                                    placeholder: "20"
                                  },
                                  domProps: { value: selectedProduct.qte },
                                  on: {
                                    keypress: function($event) {
                                      return _vm.isNumber($event)
                                    },
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          selectedProduct,
                                          "qte",
                                          $event.target.value
                                        )
                                      },
                                      _vm.getAmount
                                    ]
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Délai de l'Opération ")
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.period,
                                      expression: "period"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text", readonly: "" },
                                  domProps: { value: _vm.period },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.period = $event.target.value
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-md-2  mt-4" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn text-danger",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteProductRow(i)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "far fa-trash-alt font-size-24"
                                    })
                                  ]
                                )
                              ])
                            ])
                          }),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info",
                                  attrs: { type: "button" },
                                  on: { click: _vm.AddformDataProduct }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-plus-circle"
                                  }),
                                  _vm._v(" Ajouter")
                                ]
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }