<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ODSform from "./components/ODSform";
// import headerOds from "./components/header-ods";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
import Multiselect from "vue-multiselect";

// import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "./api";

export default {
    page: {
        title: "Base de vies de l'entreprise",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { 
      Layout, PageHeader,ODSform,/*headerOds,*/
      Multiselect,
      Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      // DatasetSearch,
      DatasetShow,
    },
    data() {
    return {
      tableData: [],
      drafts:[],
      pendings:[],
      confirms:[],
      valids:[],
      rejects:[],
      cancels:[],
      total_ods_month:0,
      disableAll:false,
      currentTotal:0,
      etat:"",
      title: "opérations 3D",
      List_ODS: [],
      selectedContract:{catering_amnt: 0},
      selectedCTT:[],
      rows:'',
      active:false,
      selectedRows:[],
      ODS:undefined,
      contracts_List:[],
      bases:[],
      items: [
        {
          text: "Catalogue des Services",
          to: {name:"service_catalog"},
        },
        {
          text: "Opération 3D",
          to: {name:"service_catalog.operations"},
        },
        {
          text: "Ordre de Service",
          active: true,
        },
      ],
      cols: [
        {
          name: 'N° ODS',
          field: 'ref',
          sort: ''
        },
        {
          name: 'Base de vie',
          field: 'lifebase',
          sort: ''
        },
        {
          name: 'Contrat',
          field: 'contract',
          sort: ''
        },
        {
          name: 'Prestataire',
          field: 'supplier',
          sort: ''
        },
        {
          name: 'Date ODS',
          field: 'date_ods',
          sort: ''
        },
        {
          name: 'Date Execution',
          field: 'date_exec_ods',
          sort: ''
        },
        {
          name: 'Coût ODS',
          field: 'total_amount',
          sort: ''
        },
        {
          name: 'Statut',
          field: 'status',
          sort: ''
        },
        {
          name: 'Reçu',
          field: 'read_at',
          sort: ''
        },
      ],
      selectedIndex:-1,
      filtredODS:[],
      selectedBdv:[],
    };
  },
  mounted(){
    this.getODS();
  },
  watch:{
      currentTotal:{
        handler(val){
          // console.log(val);
        }
      },
      ODS:{
          handler(val){
            console.log(val);
            this.refreshODS();
          }
      },
      contracts_List:{
          handler(val){
            console.log(val);
          }
      }
  },
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
        
      }
    },
    resetFilter(){
      this.selectedCTT=[];
      this.selectedBdv=[];
      this.filtredODS = this.List_ODS;
      this.etat = ""
    },
    ListODS(data){
      this.etat = data;
      if(data == 'draft') this.filtredODS = this.drafts;
      else if(data == 'pending_confirmation') this.filtredODS = this.pendings;
      else if(data == 'confirmed') this.filtredODS = this.confirms;
      else if(data == 'approved') this.filtredODS = this.valids;
      else if(data == 'rejected') this.filtredODS = this.rejects;
      else if(data == 'canceled') this.filtredODS = this.cancels;
    },
    getODS(){
      this.$http.post('/ods/OdsRestauration/listResto',{type:'threed_ops',status:false})
      .then(response => {
          this.ODS = undefined;
          this.List_ODS = response.data;
          this.filtredODS = response.data;
          this.drafts = response.data.filter(x=>(x.status == 'draft'));
          this.pendings = response.data.filter(x=>(x.status == 'pending_confirmation'));
          this.confirms = response.data.filter(x=>(x.status == 'confirmed'));
          this.valids = response.data.filter(x=>(x.status == 'approved'));
          this.rejects = response.data.filter(x=>(x.status == 'rejected'));
          this.cancels = response.data.filter(x=>(x.status == 'canceled'));
          
      })
      .catch(error => console.log(error))
    },
    refreshODS(){
      this.getODS();
      this.selectedRows=[];
      this.selectedIndex=-1;
    },
    getContractID(contract){
      if(contract.id == 0){ this.filtredODS = this.List_ODS }
      else{
        this.filtredODS = this.List_ODS;
        this.selectedContract = contract;
        let ods         = this.filtredODS.filter(x            => x.contract.id == contract.id);
        this.bases      = contract.camps_services.map(element => {return element.camp});
        this.filtredODS = ods;
      }
    },
    getLifeBaseID(lifebase){
      let ods = this.filtredODS.filter(element => element.Bdv == lifebase.id);

      this.filtredODS = ods;
    },
  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-5 mb-2 mb-md-0">
            <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'draft'}"
                  @click="ListODS('draft')"
                >
                  <i class="fas fa-file-signature"></i> <span class="badge bg-secondary text-white">{{ drafts.length }}</span> Brouillon
                </button>
                <button
                  type="button"
                  class="btn btn btn-outline-secondary"
                  :class="{'active':etat == 'pending_confirmation'}"
                  @click="ListODS('pending_confirmation')"
                >
                  <i class="far fa-paper-plane text-warning"></i> <span class="badge bg-warning text-white">{{ pendings.length }}</span> Envoyé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'confirmed'}"
                  @click="ListODS('confirmed')"
                >
                  <i class="fas fa-check text-info"></i> <span class="badge bg-info text-white">{{ confirms.length }}</span> Confirmé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'approved'}"
                  @click="ListODS('approved')"
                >
                  <i class="fas fa-check-double text-success"></i>
                  <span class="badge bg-success text-white ml-1"> {{ valids.length }}</span> Validé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'rejected'}"
                  @click="ListODS('rejected')"
                >
                  <i class="fas fa-ban text-danger"></i>
                  <span class="badge bg-danger text-white ml-1"> {{ rejects.length }}</span> Rejeté
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'canceled'}"
                  @click="ListODS('canceled')"
                >
                  <i class="fas fa-times-circle text-danger"></i>
                  <span class="badge bg-danger text-white ml-1"> {{ cancels.length }}</span> Annulé
                </button>
            </div>
          </div>
          
          <div class="col-md-3">
            <multiselect v-model="selectedCTT" :deselect-label="''" class="here" @select="getContractID" :select-label="''" :searchable="true" track-by="id" label="ref" :options="contracts_List" placeholder="Selectionner un contrat" :allow-empty="false">
              <template slot="singleContract" slot-scope="{ contract }">{{ contract.ref }}</template>
            </multiselect>
          </div>
          <div class="col-md-3">
            <multiselect v-model="selectedBdv" :deselect-label="''" class="here" @select="getLifeBaseID" :select-label="''" placeholder="Selectionner une base de vie" :searchable="true" track-by="id" label="name" :options="bases" :allow-empty="false">
              <template slot="singleOds" slot-scope="{ base }">{{ base.name }}</template>
            </multiselect>
          </div>
          <div class="col-md-1 mb-2">
            <button  type="button" @click="resetFilter" class="btn btn-warning">
                <i v-b-tooltip.hover.right="'Annuler'" class="fas fa-times-circle"></i>
            </button>
          </div>
        </div>
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste Ordres de Service (Opérations 3D)</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-0" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
                <div class=" mb-0 shadow">
                      <dataset
                        v-slot="{ ds }"
                        :ds-data="filtredODS"
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <div class="">
                              <table class="table table-hover d-md-table">
                                <thead>
                                  <tr>
                                    <th v-for="(th) in cols" :key="th.field">
                                      {{ th.name }} 
                                    </th>
                                  </tr>
                                </thead>
                                <dataset-item tag="tbody">
                                  <template #default="{ row, rowIndex }">
                                    <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                      <th>
                                        {{ row.ref }}
                                      </th>
                                      <td>{{ row.lifebase.name }}</td>
                                      <td>{{ row.contract.ref }}</td>
                                      <td>{{ row.service_provider.prestname }}</td>
                                      <td>{{ row.date_ODS_format }}</td>
                                      <td>{{ row.date_exec_ODS_format }}</td>
                                      <td>{{ row.totalAmnt.withCurrency }}</td>
                                      <td v-html="row.status_label"></td>
                                      <td v-if="row.read_at != null" class="text-success"><i  class="fas fa-check-circle text-success"></i> {{row.read_at}}</td>
                                      <td v-else><i class="fas fa-times-circle text-danger"></i></td>
                                    </tr>
                                  </template>
                                </dataset-item>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                          <dataset-show :ds-show-entries="5" />
                          <dataset-pager />
                        </div>
                      </dataset>
                    
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="mt-3">
          <!-- <headerOds :selectedContract="selectedContract" :orders="List_ODS" :selectedOds="selectedRows" :total_month="total_ods_month" :total_ods="currentTotal" @disableAll="disableAll = $event" /> -->
        </div>
        <div class="card mt-3">
            <ODSform :ODS="selectedRows" :disableButtons="disableAll" :total_month="total_ods_month" @getListContracts="contracts_List = $event" @odsvalues="ODS = $event" @currentContract="selectedContract = $event" @totalCurrentOds="currentTotal = $event" />
        </div>
      </div>
    </div>
    </Layout>
</template>